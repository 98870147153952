import React, { FunctionComponent, useState, useEffect } from "react";
import { Typography, TextField, Grid, Button, Box, Theme, useTheme } from "@material-ui/core";
import styled from "@emotion/styled";
import { Helmet } from "react-helmet";

interface SubscribeProps {
    tagline: string;
    cta: string
}

const PaddedButton = styled(Button)`
margin-left:24px;
`;

const PaddedGridTop = styled(Grid)`
padding-top: 16px;
`;

const FieldBox = styled(Box)((props: {theme: Theme}) =>`
    margin:0 0 0 auto;
    ${props.theme.breakpoints.down("xs")}{
         margin: 0 auto; 
    }
`);

const ResponsiveGrid = styled(Grid)((props: {theme: Theme}) =>`
    ${props.theme.breakpoints.down("xs")}{
         display: flex; justify-content: center; 
    }
`);

const Subscribe: FunctionComponent<SubscribeProps> = (props) => {
    const theme = useTheme<Theme>();
    const [submission, setSubmission] = useState("");
    const [email, setEmail] = useState("");
    const [emailError, setEmailError] = useState("");
    const [name, setName] = useState("");
    const [nameError, setNameError] = useState("");
    const [success, setSuccess] = useState(false);

    const defaultFormElements = {
        u: 42,
        f: 42,
        c: 0,
        m: 0,
        s: "",
        act: "sub",
        v: 2
    };

    useEffect(() => {
        window['_show_thank_you'] = (id, message, cmpUrl) => {
            setSuccess(true);

            if (cmpUrl) {
                setSubmission(cmpUrl);
            }
        }
    });

    const validateAndSubmit = (e) => {

        if (!name) {
            setNameError("Your Name Is Required");
            return;
        } else {
            setNameError("");
        }

        if (!email) {
            setEmailError("Your Email Is Required");
            return;
        } else if (!email.match(/^[\+_a-z0-9-'&=]+(\.[\+_a-z0-9-']+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,})$/i)) {
            setEmailError("Email Address Is Not Valid");
            return;
        } else {
            setEmailError("");
        }

        var serialized = Object.entries(defaultFormElements).map(kvp => `${kvp[0]}=${encodeURIComponent(kvp[1])}`)
            .concat([
                `fullname=${encodeURIComponent(name)}`,
                `email=${encodeURIComponent(email)}`,
            ]).join("&");

        var submit = `https://teamsherzai80699.activehosted.com/proc.php?${serialized}&jsonp=true`;

        setSubmission(submit);
    };
    return (
        <>
            {submission && <Helmet>
                <script src={submission}></script>
            </Helmet>}

            <Typography variant="h3" align="center" gutterBottom>{props.tagline}</Typography>
            <PaddedGridTop container justify="center" alignItems="center">
                <Grid item xs={12} sm={7}>
                    <FieldBox theme={theme}
                        maxWidth={350} px={2} pb={3}>
                        <TextField fullWidth id="name" required autoComplete="name" error={nameError != ""} label={nameError} placeholder="Name" value={name} onChange={(e) => setName(e.target.value)} />
                    </FieldBox>
                    <FieldBox theme={theme} 
                        maxWidth={350} px={2} pb={3}>
                        <TextField fullWidth id="email" required type="email" autoComplete="email" error={emailError != ""} label={emailError} placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
                    </FieldBox>
                </Grid>
                <ResponsiveGrid item xs={12} sm={5} theme={theme}>
                    {!success && <PaddedButton disabled={submission != ""} variant="contained" color="primary" onClick={validateAndSubmit}>{props.cta}</PaddedButton>}
                    {success && <Box px={4}><Typography variant="body1">Thanks for subscribing!</Typography></Box>}
                </ResponsiveGrid>
            </PaddedGridTop>
        </>
    );
}

export default Subscribe;